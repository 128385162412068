export default [
    {
        path: '/admin',
        name: 'admin-index',
        component: () => import('./../views/admin/Index.vue'),
        meta: {
            auth: true,
            roles: 'Admin',
            title: 'Admin panel'
        }
    },
    {
        path: '/admin/users',
        name: 'admin-users-index',
        component: () => import('./../views/admin/users/Index.vue'),
        meta: {
            auth: true,
            roles: 'Admin',
            title: 'Users'
        }
    },
    {
        path: '/admin/user/:id/edit',
        name: 'admin-user-edit',
        component: () => import('./../views/admin/users/Edit.vue'),
        meta: {
            auth: true,
            roles: 'Admin',
            title: 'Edit user'
        }
    }
];
