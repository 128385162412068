import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from './public'
import adminRoutes from './admin'

Vue.use(VueRouter)

const routes = [
    ...adminRoutes,
    ...publicRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active',
    routes
})

export default router
