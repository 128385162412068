export default [
    {
        path: "/",
        component: () => import("../views/landing/index.vue"),
        meta: {
            title: 'Онлайн тренажёр для обучения слепой десятипальцевой печати'
        }
    },
    {
        path: "/login",
        component: () => import("../views/Login.vue"),
        meta: {
            title: 'Страница авторизации'
        }
    },
    {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("../views/SignUp.vue"),
        meta: {
            title: 'Регистрация'
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../views/ForgotPassword.vue"),
        meta: {
            auth: false,
            title: 'Восстановление пароля'
        }
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("../views/ResetPassword.vue"),
        meta: {
            auth: false,
            title: 'Сброс пароля'
        }
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/static/PrivacyPolicy.vue"),
        meta: {
            title: 'Политика конфиденциальности https://type-master.ru'
        }
    },
    {
        path: "/oferta",
        "name": "oferta",
        component: () => import("../views/static/oferta.vue"),
        meta: {
            title: 'Публичная оферта'
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
            auth: true,
            title: 'Уроки слепой десятипальцевый печати'
        }
    },
    {
        path: "/download/files/users/result_user_:directory/:filename.txt",
        meta: {auth: true}
    },
    {
        path: "/school",
        name: "school",
        component: () => import("../views/school/index.vue"),
        meta: {
            auth: true,
            title: 'Школа десятипальцевой слепой печати'
        }
    },
    {
        path: "/certificate",
        name: "certificate",
        component: () => import("../views/certificate/index.vue"),
        meta: {
            auth: true,
            title: 'Страница выдачи сертификата'
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "notfound-403",
        component: () => import("../views/static/notfound-403.vue"),
        meta: {
            title: '403',
            headers: '404'
        }
    }
];
