<template>
    <router-view/>
</template>

<script>

import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiDotsCircle,
    mdiFlare,
} from '@mdi/js'

export default {
    name: "App",
    computed: {
        layout() {
            return `${this.$route.meta.layout || "empty"}-layout`;
        }
    },
    components: {SvgIcon},
    data: () => ({
        locale: 'en',
        dots_circle: mdiDotsCircle,
        flare: mdiFlare,
    }),
    methods: {
        init() {
        }
    },
    mounted() {
        this.init()
    }
};
</script>

<style lang="scss">
@import "../scss/app.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 10px auto;
    position: relative;
    z-index: 2;
}
</style>
